import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from '../../../components/Layout/Layout'
import Therapist from "../../../components/Therapist/Therapist"

// markup

const name = 'Myra Eben'
const license = 'MS, LPN, LPC-MH'
const areas = [
    'Chronic Medical Illness',
    'PTSD',
    'Survivors of Sexual Assualt',
    'Infertility and Infant Loss',
    'Certified Prepare/Enrich Counselor'
]

const IndexPage = () =>
(
    <Layout className="">
        <Therapist
            name={name}
            license={license}
            areas={areas}
            photo={(<StaticImage src='./myra-eben.jpg' alt={name} layout="constrained" className="shadow" />)}
        >
            <p>Myra began her career as a Licensed Professional Nurse.  She worked in Nursing Homes and Community Health, WIC programs, Hospice Cottages, and over 6 years for an Optometrist. Prior and while attending college for her BA in Psychology and MS in Counseling, she worked for an Infertility Specialist as a clinic nurse and facilitated  an infertility and infant loss group for 12 years. </p>
            <p>Once, Myra graduated with her MS in Counseling, she worked for approximately four months on a Behavioral Health Unit and then moved on to work with SPMI clientele as a Case Manager/ Therapist for five years. During that time, she began her private practice.  She has been in private practice for over seven years.  Myra works with individuals age fifteen and up and couples with a variety of issues.  She specializes in working with survivors of sexual assault, chronic medical/health issues and couples with infertility and infant loss. </p>
            <p>Myra has been married for thirty-seven years and has two grown children and three grandchildren. </p>
        </Therapist>
    </Layout>
)

export default IndexPage
